import { Button, Group } from "@mantine/core"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { supportModalOpenState, hasValidLicenseState, licenseKeyState, licenseModalOpenState, upgradeModalOpenState } from "state/StartupState"
import { useEffect } from "react"

function UpgradePrompt() {
    const licenseKey = useRecoilValue(licenseKeyState)

    const setOpenLicenseModal = useSetRecoilState(licenseModalOpenState)
    const setOpenUpgradeModal = useSetRecoilState(upgradeModalOpenState)
    const setOpenSupportModal = useSetRecoilState(supportModalOpenState)
    const [hasValidLicense, setHasValidLicense] = useRecoilState(hasValidLicenseState)

    useEffect(() => {
        const func = async () => {
            if (!licenseKey || hasValidLicense) {
                return
            }

            const apiUrl = `https://api.gumroad.com/v2/licenses/verify?product_id=78PqBGWaDZAmwJ8AUpZU9A==&license_key=${licenseKey}&increment_uses_count=false`
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if (response.status !== 404) {
                setHasValidLicense(true)
            }
        }

        func()
    }, [hasValidLicense, licenseKey, setHasValidLicense])

    return (
        <>
            {hasValidLicense && (
                <Group sx={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    marginTop: 20,
                    marginRight: 35,
                    zIndex: 100,
                }}
                spacing={0}
                >
                    <Button onClick={() => setOpenSupportModal(true)} variant="text">
                        Support & FAQs
                    </Button>
                </Group>
            )}

            {!hasValidLicense && (
                <Group sx={{
                    position: "fixed",
                    top: 0,
                    right: 0,
                    marginTop: 20,
                    marginRight: 10,
                    zIndex: 100,
                }}
                spacing={0}
                >
                    <Button onClick={() => setOpenLicenseModal(true)} variant="text">
                        I have a license key
                    </Button>

                    <Button onClick={() => setOpenUpgradeModal(true)} variant="text">
                        Unlock all features ✨
                    </Button>
                </Group>
            )}
        </>
    )
}

export default UpgradePrompt
