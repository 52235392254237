import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Container, MantineProvider } from "@mantine/core"
import Home from "pages/home/Home"
import { RecoilRoot } from "recoil"
import Path from "constants/Path"
import FoundersFactory from "pages/funds/FoundersFactory"
import Techstars from "pages/funds/Techstars"
import YCombinator from "pages/funds/YCombinator"
import Custom from "pages/funds/Custom"
import UpgradePrompt from "pages/upgrade/UpgradePrompt"
import UpgradeModal from "pages/upgrade/UpgradeModal"
import LicenseModal from "pages/upgrade/LicenseModal"
import Seeedcamp from "pages/funds/Seeedcamp"
import { Notifications } from "@mantine/notifications"
import SupportModal from "pages/upgrade/SupportModal"
import ThankYou from "pages/thank-you/ThankYou"
import NotFound from "pages/error/NotFound"
import ProductHunt from "pages/funds/ProductHunt"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)

const router = createBrowserRouter([
  {
    path: Path.Home,
    element: <Home />
  },
  {
    path: Path.FoundersFactory,
    element: <FoundersFactory />
  },
  {
    path: Path.Seedcamp,
    element: <Seeedcamp />
  },
  {
    path: Path.Techstars,
    element: <Techstars />
  },
  {
    path: Path.YCombinator,
    element: <YCombinator />
  },
  {
    path: Path.ProductHunt,
    element: <ProductHunt />
  },
  {
    path: Path.Custom,
    element: <Custom />
  },
  {
    path: Path.ThankYou,
    element: <ThankYou />
  },
  {
    path: "*",
    element: <NotFound />
  }
])

root.render(
  <MantineProvider
    withGlobalStyles
    withNormalizeCSS
    theme={{
      colorScheme: "dark",
      primaryColor: "orange",
    }}
  >
    <Container my={40} size={800}>
      <RecoilRoot>
        <LicenseModal />
        <UpgradeModal />
        <SupportModal />
        <UpgradePrompt />
        <Notifications />

        <RouterProvider router={router} />
      </RecoilRoot>
    </Container>
  </MantineProvider>
)
