import { Grid } from "@mantine/core"
import ApplicationForm, { Question } from "pages/application/ApplicationForm"
import ApplicationHeader from "pages/application/ApplicationHeader"
import { ACCELERATORS } from "pages/home/AcceleratorList"

function Seedcamp() {
    const { name, logo, status, url } = ACCELERATORS.find(accelerator => accelerator.name === "Seedcamp")!

    const questions: Question[] = [
        {
            question: "What is your company's elevator pitch? (max 300 characters)",
            maxLength: 300,
            isFree: true,
        },
        {
            question: "Why are you the right team to build this company? (max 300 characters)",
            maxLength: 300,
            isFree: true,
        },
        {
            question: "When did you start working on your company?",
        },
        {
            question: "If you are already live, how many customers/users do you have? (max 300 characters)",
            maxLength: 300,
        },
        {
            question: "What are some of the major milestones you have achieved since starting your company?",
        },
        {
            question: "Who are your two closest competitors? Why is your solution better? (max 300 characters)",
            maxLength: 300,
        },
        {
            question: "Were you referred to Seedcamp by somebody? If yes, by who?",
        },
        {
            question: "Have you applied for funding from Seedcamp before? If yes, when?",
        }
    ]

    return (
        <Grid>
            <Grid.Col xs={12}>
                <ApplicationHeader
                    name={name}
                    logo={logo}
                    status={status}
                    url={url}
                />
            </Grid.Col>

            <Grid.Col xs={12}>
                <ApplicationForm
                    fundName={name}
                    questions={questions}
                />
            </Grid.Col>
        </Grid>
    )
}

export default Seedcamp
