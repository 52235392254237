import { Button, Grid, Modal, TextInput } from "@mantine/core"
import ApplicationForm, { Question } from "pages/application/ApplicationForm"
import ApplicationHeader from "pages/application/ApplicationHeader"
import { useState } from "react"
import { useDisclosure } from "@mantine/hooks"
import { ACCELERATORS } from "pages/home/AcceleratorList"

function Custom() {
    const { logo, status } = ACCELERATORS.find(accelerator => accelerator.name === "Not listed here? Create custom form")!
    const name = "Custom form"

    const [question, setQuestion] = useState<string>("")
    const [questions, setQuestions] = useState<Question[]>([])
    const [opened, { open, close }] = useDisclosure(false)

    return (
        <>
            <Grid>
                <Grid.Col xs={12}>
                    <ApplicationHeader
                        name={name}
                        logo={logo}
                        status={status}
                        isCustom={true}
                    />
                </Grid.Col>

                <Grid.Col xs={12}>
                    <ApplicationForm
                        fundName={name}
                        questions={questions}
                        isCustom={true}
                    />
                </Grid.Col>

                <Grid.Col xs={12}>
                    <Button onClick={open}>
                        Add custom question
                    </Button>
                </Grid.Col>
            </Grid>

            <Modal opened={opened} onClose={close} title="Add Question">
                <Grid>
                    <Grid.Col xs={12}>
                        <TextInput
                            label="Question"
                            placeholder="What is your company going to make?"
                            onChange={(e) => setQuestion(e.currentTarget.value)}
                            mb={10}
                        />
                    </Grid.Col>

                    <Grid.Col xs={12}>
                        <Button onClick={() => {
                            setQuestions([
                                ...questions,
                                { question },
                            ])

                            close()
                        }}>
                            Add to application
                        </Button>
                    </Grid.Col>
                </Grid>
            </Modal>
        </>
    )
}

export default Custom
