import { createStyles, Title, Text, Button, Container, Group, rem } from "@mantine/core"

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(80),
        paddingBottom: rem(80),
    },

    label: {
        textAlign: "center",
        fontWeight: 900,
        fontSize: rem(220),
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color: theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan("sm")]: {
            fontSize: rem(120),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: "center",
        fontWeight: 900,
        fontSize: rem(38),

        [theme.fn.smallerThan("sm")]: {
            fontSize: rem(32),
        },
    },

    description: {
        maxWidth: rem(500),
        margin: "auto",
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
}))

function NotFound() {
    const { classes } = useStyles()

    return (
        <Container className={classes.root}>
            <div className={classes.label}>
                🚀
            </div>

            <Title className={classes.title}>
                Thank you for supporting PitchPal!
            </Title>

            <Text color="dimmed" size="lg" align="center" className={classes.description}>
                You will receive an email with your license key that you can activate by
                clicking <b>I have a license key</b> in the top right corner of this page.
            </Text>

            <Group position="center">
                <Button variant="subtle" size="md" onClick={() => window.location.href = "/"}>
                    All done? Lets take your startup to the moon!
                </Button>
            </Group>
        </Container>
    )
}

export default NotFound
