import { Button, Grid, Group, Modal, Text, TextInput } from "@mantine/core"
import { useRecoilState, useSetRecoilState } from "recoil"
import { licenseKeyState, licenseModalOpenState, upgradeModalOpenState } from "state/StartupState"
import { useState } from "react"
import { notifications } from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"

function LicenseModal() {
    const [unvalidatedLicenseKey, setUnvalidatedLicenseKey] = useState("")
    const [open, setOpen] = useRecoilState(licenseModalOpenState)
    const setLicenseKey = useSetRecoilState(licenseKeyState)
    const setOpenUpgradeModal = useSetRecoilState(upgradeModalOpenState)
    const setOpenLicenseModal = useSetRecoilState(licenseModalOpenState)

    const activeLicenseKey = async () => {
        if (unvalidatedLicenseKey === "") {
            notifications.show({
                title: "Missing license key",
                message: "Please enter a license key",
                autoClose: 10000,
                icon: <IconX size="1.1rem" />,
                color: "red",
            })
            
            return
        }

        const apiUrl = `https://api.gumroad.com/v2/licenses/verify?product_id=78PqBGWaDZAmwJ8AUpZU9A==&license_key=${unvalidatedLicenseKey}`
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })

        if (response.status === 404) {
            notifications.show({
                title: "Invalid license key",
                message: "The license key you provided was invalid",
                autoClose: 10000,
                icon: <IconX size="1.1rem" />,
                color: "red",
            })
        } else {
            const json = await response.json()

            if (json["uses"] > 10) {
                notifications.show({
                    title: "Too many activations",
                    message: "The license key has been activated >10 times. Please contact sam@pitchpal.app for support.",
                    autoClose: 10000,
                    icon: <IconX size="1.1rem" />,
                    color: "red",
                })
            } else {
                setLicenseKey(unvalidatedLicenseKey)

                notifications.show({
                    title: "Activated license key",
                    message: "Your license key has been successfully activated",
                    icon: <IconCheck size="1.1rem" />,
                    autoClose: 10000,
                    color: "teal",
                })
    
                setOpen(false)
            }
        }
    }

    return (
        <Modal
            size="md"
            opened={open}
            onClose={() => setOpen(false)}
            withCloseButton={false}
            overlayProps={{
                opacity: 0.55,
                blur: 3,
            }}
            transitionProps={{ transition: "rotate-left" }}
        >
            <Grid>
                <Grid.Col xs={12}>
                    <TextInput
                        label={
                            <Text mb={5} size={16}>
                                Activate your license key ✨
                            </Text>
                        }
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        description={
                            <Text mb={15}>
                                You can find this in your email receipt.<br />Need help? Contact <a href="mailto:sam@pitchpal.app">sam@pitchpal.app</a>
                            </Text>
                        }
                        onChange={(event) => setUnvalidatedLicenseKey(event.currentTarget.value)}
                        mb={10}
                    />
                </Grid.Col>

                <Grid.Col xs={12}>
                    <Group spacing="xs">
                        <Button onClick={activeLicenseKey}>
                            Activate
                        </Button>

                        <Button
                            variant="text"
                            onClick={() => {
                                setOpenLicenseModal(false)
                                setOpenUpgradeModal(true)
                            }}
                        >
                            Get license key
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Modal >
    )
}

export default LicenseModal
