enum Route {
    Home = "/",
    FoundersFactory = "/founders-factory",
    Seedcamp = "/seedcamp",
    Techstars = "/techstars",
    YCombinator = "/ycombinator",
    ProductHunt = "/product-hunt",
    Custom = "/custom",
    ThankYou = "/thank-you",
    NotFound = "/not-found",
}

export default Route
