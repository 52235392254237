import { Grid, TextInput, Textarea, Text } from "@mantine/core"
import { useRecoilState } from "recoil"
import { startupNameState, startupDescriptionState, founderDescriptionState } from "state/StartupState"

function StartupDetail() {
    const [startupName, setStartupName] = useRecoilState(startupNameState)
    const [startupDescription, setStartupDescription] = useRecoilState(startupDescriptionState)
    const [founderDescription, setFounderDescription] = useRecoilState(founderDescriptionState)

    return (
        <Grid>
            <Grid.Col xs={12}>
                <Text>
                    What's your startup called?
                </Text>

                <TextInput
                    mt={5}
                    placeholder="Reddit"
                    defaultValue={startupName}
                    onChange={(e) => setStartupName(e.target.value)}
                />
            </Grid.Col>

            <Grid.Col xs={12} mt={10}>
                <Text>
                    Briefly describe your startup and its services.
                </Text>

                <Text color="dimmed" size="sm">
                    When did you start? What problem are you solving? How are you solving it?
                </Text>

                <Textarea
                    mt={5}
                    minRows={3}
                    placeholder="Reddit is an online bulletin board where users can create communities, called 'subreddits', dedicated to specific topics. Reddit has a unique voting system where users can upvote or downvote posts and comments, to determine their visibility."
                    defaultValue={startupDescription}
                    onChange={(e) => setStartupDescription(e.target.value)}
                />
            </Grid.Col>

            <Grid.Col xs={12} mt={10}>
                <Text>
                    Briefly describe the founders and their experience.
                </Text>

                <Text color="dimmed" size="sm">
                    Who's involved? What are your backgrounds? What are your roles in the startup?
                </Text>

                <Textarea
                    mt={5}
                    minRows={3}
                    placeholder="Reddit was founded by myself (Steve Huffman) and Alexis Ohanian in 2005. I have a background in computer science and Alexis has a background in business and entrepreneurship. We're both passionate about empowering users to share and discuss their hobbies."
                    defaultValue={founderDescription}
                    onChange={(e) => setFounderDescription(e.target.value)}
                />
            </Grid.Col>
        </Grid>
    )
}

export default StartupDetail
