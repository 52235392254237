import { atom } from "recoil"
import { recoilPersist } from "recoil-persist"

const { persistAtom } = recoilPersist()

const licenseKeyState = atom({
  key: "licenseKeyState",
  default: "",
  effects: [persistAtom],
})

const startupNameState = atom({
  key: "startupNameState",
  default: "",
  effects: [persistAtom],
})

const startupDescriptionState = atom({
  key: "startupDescriptionState",
  default: "",
  effects: [persistAtom],
})

const founderDescriptionState = atom({
  key: "founderDescriptionState",
  default: "",
  effects: [persistAtom],
})

const licenseModalOpenState = atom({
  key: "licenseModalOpenState",
  default: false,
})

const hasValidLicenseState = atom({
  key: "hasValidLicenseState",
  default: false,
  effects: [persistAtom],
})

const upgradeModalOpenState = atom({
  key: "upgradeModalOpenState",
  default: false,
})

const supportModalOpenState = atom({
  key: "supportModalOpenState",
  default: false,
})

export {
  licenseKeyState,
  startupNameState,
  startupDescriptionState,
  founderDescriptionState,
  licenseModalOpenState,
  upgradeModalOpenState,
  hasValidLicenseState,
  supportModalOpenState,
}
