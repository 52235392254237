import { Grid } from "@mantine/core"
import ApplicationForm, { Question } from "pages/application/ApplicationForm"
import ApplicationHeader from "pages/application/ApplicationHeader"
import { ACCELERATORS } from "pages/home/AcceleratorList"

function FoundersFactory() {
    const { name, logo, status, url } = ACCELERATORS.find(accelerator => accelerator.name === "Founders Factory")!

    const questions: Question[] = [
        {
            question: "What problem is your startup solving? (max 100 words)",
            isFree: true,
        },
        {
            question: "How are you solving this problem? (max 100 words)",
        },
        {
            question: "Please provide a few bullets on your current traction (monthly revenue, users, pilots etc) (max 100 words)",
        },
    ]

    return (
        <Grid>
            <Grid.Col xs={12}>
                <ApplicationHeader
                    name={name}
                    logo={logo}
                    status={status}
                    url={url}
                />
            </Grid.Col>

            <Grid.Col xs={12}>
                <ApplicationForm
                    fundName={name}
                    questions={questions}
                />
            </Grid.Col>
        </Grid>
    )
}

export default FoundersFactory
