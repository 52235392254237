import { Card, Grid, Center, Title, List, ThemeIcon, Text } from "@mantine/core"
import { IconNumber1, IconNumber2, IconNumber3 } from "@tabler/icons-react"

function Overview() {
    return (
        <Card mb={20} withBorder sx={{ width: "100%" }}>
            <Grid>
                <Grid.Col xs={12}>
                    <Center>
                        <Title order={1}>
                            Pitch<span style={{ color: "rgb(255, 130, 63)" }}>Pal</span>
                        </Title>
                    </Center>

                    <Center>
                        <Text color="dimmed">
                            Your shortcut to startup funding, powered by AI.
                        </Text>
                    </Center>
                </Grid.Col>

                <Grid.Col xs={12}>
                    <Title order={4}>
                        How does it work?
                    </Title>

                    <List
                        mt={10}
                        spacing="xs"
                        size="sm"
                        center
                    >
                        <List.Item icon={
                            <ThemeIcon color="rgb(255, 130, 63)" size={24} radius="xl">
                                <IconNumber1 size="1rem" />
                            </ThemeIcon>
                        }>
                            Briefly describe your startup and founders.
                        </List.Item>

                        <List.Item icon={
                            <ThemeIcon color="rgb(255, 130, 63)" size={24} radius="xl">
                                <IconNumber2 size="1rem" />
                            </ThemeIcon>
                        }>
                            Select the accelerator you want to apply to.
                        </List.Item>

                        <List.Item icon={
                            <ThemeIcon color="rgb(255, 130, 63)" size={24} radius="xl">
                                <IconNumber3 size="1rem" />
                            </ThemeIcon>
                        }>
                            Watch as PitchPal AI auto-magically generates a perfect application for you ✨
                        </List.Item>
                    </List>

                    <Text mt={15} size={15}>
                        Generated answers are tailored to meet the requirements of the accelerator you're applying to, taking into account their application guidelines, portfolio, team perspectives, etc.
                    </Text>
                </Grid.Col>
            </Grid>
        </Card>
    )
}

export default Overview
