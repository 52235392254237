import { Image, Group, Text, Stack, Grid, Divider, Button } from "@mantine/core"
import { IconArrowLeft, IconExternalLink } from "@tabler/icons-react"
import Path from "constants/Path"

interface ApplicationHeaderProps {
    name: string,
    logo: string,
    status: string,
    isCustom?: boolean,
    url?: string,
}

function ApplicationHeader({ name, logo, status, isCustom, url }: ApplicationHeaderProps) {
    return (
        <Grid>
            <Grid.Col xs={12}>
                <Button
                    leftIcon={<IconArrowLeft size={15} />}
                    variant="text"
                    onClick={() => { window.location.pathname = Path.Home }}
                >
                    Back home
                </Button>
            </Grid.Col>

            <Grid.Col xs={12} mt={10}>
                <Group position="apart">
                    <Group position="left">
                        <Image
                            src={logo}
                            height={50}
                            width="auto"
                            radius={5}
                        />

                        <Stack spacing={0}>
                            <Text>
                                {name}
                            </Text>

                            <Text color="dimmed">
                                {status}
                            </Text>
                        </Stack>
                    </Group>

                    {!isCustom && (
                        <Text>
                            <Button variant="text" onClick={() => window.open(url, "_blank")}>
                                <Text mr={3}>
                                    Open funding application
                                </Text>

                                <IconExternalLink size={18} />
                            </Button>
                        </Text>
                    )}
                </Group>
            </Grid.Col>

            <Grid.Col xs={12}>
                <Divider my={15} />
            </Grid.Col>
        </Grid>
    )
}

export default ApplicationHeader
