import { Grid } from "@mantine/core"
import AcceleratorList from "pages/home/AcceleratorList"
import Overview from "pages/home/Overview"
import StartupDetail from "pages/home/StartupDetail"

function Home() {
    return (
        <>
            <Grid>
                <Grid.Col xs={12}>
                    <Overview />
                </Grid.Col>

                <Grid.Col xs={12}>
                    <StartupDetail />
                </Grid.Col>

                <Grid.Col xs={12}>
                    <AcceleratorList />
                </Grid.Col>
            </Grid>
        </>
    )
}

export default Home
