import { Accordion, Button, Center, Divider, Group, List, Modal, Stack, Text, Title } from "@mantine/core"
import { IconArrowRight, IconSquareCheckFilled } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { useRecoilState, useSetRecoilState } from "recoil"
import { licenseModalOpenState, upgradeModalOpenState } from "state/StartupState"

function UpgradeModal() {
    const [openUpgradeModal, setOpenUpgradeModal] = useRecoilState(upgradeModalOpenState)
    const setOpenLicenseModal = useSetRecoilState(licenseModalOpenState)

    const buyNow = () => {
        window.open("https://pitchpal.gumroad.com/l/lifetime-access", "_blank")
    }

    return (
        <Modal
            size="md"
            opened={openUpgradeModal}
            onClose={() => setOpenUpgradeModal(false)}
            withCloseButton={false}
            overlayProps={{
                opacity: 0.55,
                blur: 3,
            }}
            transitionProps={{ transition: "rotate-left" }}
        >
            <Center>
                <Group position="center">
                    <Text size={24} sx={{ fontWeight: "bold" }}>
                        Lifetime Access 🥳
                    </Text>
                </Group>
            </Center>

            <Group position="center">
                <Text
                    size={24}
                    sx={{
                        fontWeight: 800,
                        textDecoration: "line-through",
                        color: "gray",
                    }}
                >
                    $39
                </Text>

                <Text
                    size={48}
                    sx={{
                        fontWeight: 800,
                        color: "#22C55E",
                    }}
                >
                    $19
                </Text>
            </Group>

            <Stack spacing={0}>
                <Text color="dimmed" size={12} sx={{ fontWeight: 400, textAlign: "center" }}>
                    Buy once, use forever. Future updates included.
                </Text>

                <Text color="#22C55E" size={12} sx={{ fontWeight: 400, textAlign: "center", marginTop: 10 }}>
                    Buy now to get <b>Custom Forms</b> included for free!
                </Text>

                <Text color="#22C55E" size={12} sx={{ fontWeight: 400, textAlign: "center" }}>
                    Launch deal ends soon.
                </Text>
            </Stack>

            <List
                spacing="xs"
                size="sm"
                center
                mt={30}
                px={40}
                icon={<IconSquareCheckFilled color="#22C55E" size={24} />}
            >
                <List.Item>
                    Unlock all applications & questions

                    <Text color="dimmed">
                        Access Y Combinator, Seedcamp, etc.
                    </Text>
                </List.Item>

                <List.Item>
                    Build custom application forms

                    <Text color="dimmed">
                        Setup your own questions
                    </Text>
                </List.Item>

                <List.Item>
                    Email investors (coming soon)

                    <Text color="dimmed">
                        Generate tailored investor emails
                    </Text>
                </List.Item>

                <List.Item>
                    All future updates included

                    <Text color="dimmed">
                        New features, bug fixes, etc.
                    </Text>
                </List.Item>

                <List.Item>
                    Self-host the app

                    <Text color="dimmed">
                        Option to run the app locally, or on your own server (using OpenAI API key)
                    </Text>
                </List.Item>
            </List>

            <Group position="center" mt={30}>
                <Stack spacing={0}>
                    <a href="https://pitchpal.gumroad.com/l/lifetime-access" target="_blank" rel="noreferrer">
                        <Button color="#22C55E" sx={{ width: "100%" }}>
                            Buy now
                            <IconArrowRight size={20} />
                        </Button>
                    </a>

                    <Button
                        variant="text"
                        onClick={() => {
                            setOpenUpgradeModal(false)
                            setOpenLicenseModal(true)
                        }}
                        mt={10}
                    >
                        I already have a license
                    </Button>
                </Stack>
            </Group>

            <Divider mt={40} mb={40} />

            <Title order={3} mt={20} mb={15}>
                Frequently Asked Questions
            </Title>

            <Accordion variant="separated">
                <Accordion.Item value="a">
                    <Accordion.Control>
                        How do I use PitchPal?
                    </Accordion.Control>

                    <Accordion.Panel>
                        Simply provide high-level details about your startup and it's founders, and PitchPal will generate
                        a completed application form for the most popular accelerators and incubators.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="b">
                    <Accordion.Control>
                        What are custom forms?
                    </Accordion.Control>

                    <Accordion.Panel>
                        Custom forms allow you to create your own application forms, using your own questions. This is useful
                        if you're applying to an accelerator or incubator that isn't <b>yet</b> supported by PitchPal.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="x">
                    <Accordion.Control>
                        Is there an expiration date for my license key?
                    </Accordion.Control>

                    <Accordion.Panel>
                        No, your license key will never expire.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="y">
                    <Accordion.Control>
                        Will I receive future updates?
                    </Accordion.Control>

                    <Accordion.Panel>
                        Yes, you will receive all future updates, including new features, bug fixes, etc.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="c">
                    <Accordion.Control>
                        How many devices can I activate my license on?
                    </Accordion.Control>

                    <Accordion.Panel>
                        You can activate your license key on 10 devices, which is more than enough for most people.
                        A device can either be standalone devices you own (e.g. your laptop and phone),
                        or separate browsers on the same device (e.g. activating PitchPal on both the Chrome and Firefox
                        browsers on your laptop). Your license key is personal to you, and cannot be shared with others.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="d">
                    <Accordion.Control>
                        How do I activate my license?
                    </Accordion.Control>

                    <Accordion.Panel>
                        After purchasing a license, you will receive an email with your license key. Simply click the
                        <b>I have a license key</b> button on <a href="https://pitchpal.app" target="_blank" rel="noreferrer">PitchPal.app</a>,
                        copy and paste your license key from the email, and click <b>Activate</b> to instantly unlock all premium features.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="e">
                    <Accordion.Control>
                        Can I self-host the app?
                    </Accordion.Control>

                    <Accordion.Panel>
                        Yes! After purchasing, you will receive a link to download the (compiled) static web app, along with instructions on how to run it on your own server. An OpenAI API key will be needed to self-host the app.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="f">
                    <Accordion.Control>
                        Do I have access to the full source code when self-hosting?
                    </Accordion.Control>

                    <Accordion.Panel>
                        No, the license key only grants you permission to run the app locally or on your own server for personal use, so you won't be able to change or redistribute the code.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="g">
                    <Accordion.Control>
                        I forgot my license key, how can I get it back?
                    </Accordion.Control>

                    <Accordion.Panel>
                        Please email <a href="mailto:sam@pitchpal.app">sam@pitchpal.app</a> from the email address you used to purchase the license key, and we will resend it.
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Modal>
    )
}

export default UpgradeModal
