import { Grid } from "@mantine/core"
import ApplicationForm, { Question } from "pages/application/ApplicationForm"
import ApplicationHeader from "pages/application/ApplicationHeader"
import { ACCELERATORS } from "pages/home/AcceleratorList"

function YCombinator() {
    const { name, logo, status, url } = ACCELERATORS.find(accelerator => accelerator.name === "Y Combinator")!

    const questions: Question[] = [
        {
            question: "Describe what your company does in 50 characters or less.",
            maxLength: 50,
            isFree: true,
        },
        {
            question: "What is your company going to make? Please describe your product and what it does or will do.",
            isFree: true,
        },
        {
            question: "Who writes code, or does other technical work on your product? Was any of it done by a non-founder? Please explain.",
        },
        {
            question: "Are you looking for a cofounder?",
        },
        {
            question: "How far along are you?",
        },
        {
            question: "How long have each of you been working on this? How much of that has been full-time? Please explain.",
        },
        {
            question: "If you are applying with the same idea as a previous batch, did anything change? If you applied with a different idea, why did you pivot and what did you learn from the last idea?",
        },
        {
            question: "If you have already participated or committed to participate in an incubator, \"accelerator\" or \"pre-accelerator\" program, please tell us about it.",
        },
        {
            question: "Why did you pick this idea to work on? Do you have domain expertise in this area? How do you know people need what you're making?",
        },
        {
            question: "Who are your competitors? What do you understand about your business that they don't?",
        },
        {
            question: "How do or will you make money? How much could you make?",
            description: "(We realize you can't know precisely, but give your best estimate.)",
        },
        {
            question: "If you have not formed the company yet, describe the planned equity ownership breakdown among the founders, employees and any other proposed stockholders. If there are multiple founders, be sure to give the proposed equity ownership of each founder and founder title (e.g. CEO).",
            description: "(This question is as much for you as us.)",
        },
        {
            question: "If you had any other ideas you considered applying with, please list them. One may be something we've been waiting for. Often when we fund people it's to do something they list here and not in the main application.",
        },
        {
            question: "What convinced you to apply to Y Combinator? Did someone encourage you to apply? Have you been to any YC events?",
        },
        {
            question: "How did you hear about Y Combinator?",
        },
    ]

    return (
        <Grid>
            <Grid.Col xs={12}>
                <ApplicationHeader
                    name={name}
                    logo={logo}
                    status={status}
                    url={url}
                />
            </Grid.Col>

            <Grid.Col xs={12}>
                <ApplicationForm
                    fundName={name}
                    questions={questions}
                />
            </Grid.Col>
        </Grid>
    )
}

export default YCombinator
