import { Grid, TextInput, Textarea, Tooltip, ActionIcon, Text, Loader, Stack, Center, Button, Overlay } from "@mantine/core"
import { IconRefresh } from "@tabler/icons-react"
import { useState, useEffect } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { startupNameState, startupDescriptionState, founderDescriptionState, licenseKeyState, upgradeModalOpenState } from "state/StartupState"
import hash from "hash.js"

export interface Question {
    question: string
    description?: string
    maxLength?: number
    isFree?: boolean
}

interface ApplicationFormProps {
    fundName: string
    questions: Question[]
    isCustom?: boolean
}

function ApplicationForm({ fundName, questions, isCustom }: ApplicationFormProps) {
    const companyName = useRecoilValue(startupNameState)
    const companyDescription = useRecoilValue(startupDescriptionState)
    const founderDescription = useRecoilValue(founderDescriptionState)
    const licenseKey = useRecoilValue(licenseKeyState)
    const setOpen = useSetRecoilState(upgradeModalOpenState)

    const [responses, setResponses] = useState<Record<string, string>>({})
    const [loading, setLoading] = useState(true && !isCustom)
    const [hasValidLicense, setHasValidLicense] = useState(false)

    useEffect(() => {
        const func = async () => {
            const apiUrl = `https://api.gumroad.com/v2/licenses/verify?product_id=78PqBGWaDZAmwJ8AUpZU9A==&license_key=${licenseKey}&increment_uses_count=false`
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })

            if (response.status === 404) {
                setHasValidLicense(false)
            } else {
                setHasValidLicense(true)
            }
        }

        if (licenseKey) {
            func()
        }
    }, [licenseKey])

    useEffect(() => {
        const func = async () => {
            const apiUrl = "https://d66r5h3zqpel2n3z74xz4thrna0xzvez.lambda-url.eu-west-2.on.aws/"

            let body: any = {
                licenseKey,
                fundName,
                companyName,
                companyDescription,
                founderDescription,
            }

            if (isCustom) {
                body = {
                    ...body,
                    customQuestions: questions.map((question) => question.question),
                }
            }

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })

            const json = await response.json()

            setResponses(json)
            setLoading(false)
        }

        func()
    }, [companyDescription, companyName, founderDescription, fundName, isCustom, licenseKey, questions])

    const regenerateRespone = async (question: string) => {
        const apiUrl = "https://d66r5h3zqpel2n3z74xz4thrna0xzvez.lambda-url.eu-west-2.on.aws/"

        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                licenseKey,
                fundName,
                companyName,
                companyDescription,
                founderDescription,
                customQuestions: [question],
            }),
        })

        const json = await response.json()

        setResponses((responses) => ({
            ...responses,
            [question]: json[question],
        }))
    }

    return (
        <Grid>
            {loading && (
                <>
                    <Grid.Col xs={12} mt={50}>
                        <Center>
                            <Loader size="lg" variant="bars" />
                        </Center>
                    </Grid.Col>

                    <Grid.Col xs={12}>
                        <Center>
                            <Text size="xl" weight={700}>
                                Generating your application...
                            </Text>
                        </Center>
                    </Grid.Col>
                </>
            )}

            {!loading && (
                <>
                    <Grid.Col xs={12}>
                        <TextInput
                            label={
                                <Text mb={5} size={16}>
                                    Company name
                                </Text>
                            }
                            defaultValue={companyName}
                            mb={10}
                        />
                    </Grid.Col>

                    <>
                        {questions.map(({ question, description, maxLength, isFree }) => {
                            const response = responses[question] ?? ""
                            const key = hash
                                .sha256()
                                .update(question + " " + response)
                                .digest("hex")

                            return (
                                <Grid.Col xs={12} key={key}>
                                    <Textarea
                                        disabled={!isFree && !hasValidLicense}
                                        placeholder={isFree || hasValidLicense ? "Generating..." : "Upgrade to unlock all questions & applications"}
                                        label={
                                            <Text mb={5} size={16}>
                                                {question}
                                            </Text>
                                        }
                                        maxLength={maxLength}
                                        description={description}
                                        defaultValue={response}
                                        rightSection={
                                            <Tooltip label="Regenerate">
                                                <ActionIcon onClick={() => regenerateRespone(question)} disabled={!isFree && !hasValidLicense}>
                                                    <IconRefresh size={20} />
                                                </ActionIcon>
                                            </Tooltip>
                                        }
                                        autosize
                                        mb={10}
                                    />
                                </Grid.Col>
                            )
                        })}
                    </>

                    {!licenseKey && (
                        <Grid.Col xs={12}>
                            <Overlay blur={4} fixed sx={{ top: "70vh" }} center>
                                <Stack>
                                    <Text size="xl" weight={700}>
                                        <Center>
                                            {isCustom && (
                                                <>
                                                    Upgrade to use custom forms (+ lots more)
                                                </>
                                            )}

                                            {!isCustom && (
                                                <>
                                                    Upgrade to access your {fundName} application (+ lots more)
                                                </>
                                            )}
                                        </Center>
                                    </Text>

                                    <Text color="dimmed" mb={10} mt={-10}>
                                        <Center>
                                            One time payment, no subscription.
                                        </Center>
                                    </Text>

                                    <Button onClick={() => setOpen(true)} size="md">
                                        Upgrade now (50% off for launch)
                                    </Button>
                                </Stack>
                            </Overlay>
                        </Grid.Col>
                    )}
                </>
            )}
        </Grid>
    )
}

export default ApplicationForm
