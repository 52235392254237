import { Grid } from "@mantine/core"
import ApplicationForm, { Question } from "pages/application/ApplicationForm"
import ApplicationHeader from "pages/application/ApplicationHeader"
import { ACCELERATORS } from "pages/home/AcceleratorList"

function Techstars() {
    const { name, logo, status, url } = ACCELERATORS.find(accelerator => accelerator.name === "Techstars")!

    const questions: Question[] = [
        {
            question: "Enter your startup tag-line (max 50 characters)",
            maxLength: 50,
            isFree: true,
        },
        {
            question: "What does your startup do? Answer in the form \"For [Customers] that [Problem], we [what you do]\"",
            isFree: true,
        },
        {
            question: "Do you have any co-founders?",
        },
        {
            question: "How do (or will you) make money? How much could you make?",
        },
        {
            question: "Who is your target audience?",
        },
        {
            question: "Who are your competitors or might become competitors?",
        },
        {
            question: "What is your competitive advantage?",
        },
        {
            question: "Why is this the right time now?",
        },
        {
            question: "What is your customer acquisition strategy?",
        },
        {
            question: "What is your grand vision, where do you see this going 5-10 years from now?",
        },
    ]

    return (
        <Grid>
            <Grid.Col xs={12}>
                <ApplicationHeader
                    name={name}
                    logo={logo}
                    status={status}
                    url={url}
                />
            </Grid.Col>

            <Grid.Col xs={12}>
                <ApplicationForm
                    fundName={name}
                    questions={questions}
                />
            </Grid.Col>
        </Grid>
    )
}

export default Techstars
