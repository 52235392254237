import { Grid, Group, TextInput, Card, Stack, Image, Text } from "@mantine/core"
import { useDebouncedState } from "@mantine/hooks"
import { IconSearch, IconArrowRight } from "@tabler/icons-react"
import { useState, useEffect } from "react"
import Path from "constants/Path"

interface Accelerator {
    name: string
    logo: string
    path: string
    status: string
    url: string
}

export const ACCELERATORS: Accelerator[] = [
    {
        name: "Y Combinator",
        logo: "https://i.imgur.com/BG5anDo.png",
        path: Path.YCombinator,
        status: "Open",
        url: "https://www.ycombinator.com/apply",
    },
    {
        name: "Seedcamp",
        logo: "https://i.imgur.com/HTFOWLm.png",
        path: Path.Seedcamp,
        status: "Open",
        url: "https://seedcamp.com/looking-for-funding/"
    },
    {
        name: "Techstars",
        logo: "https://i.imgur.com/bl4C0jS.png",
        path: Path.Techstars,
        status: "Varies by program",
        url: "https://www.techstars.com/accelerators",
    },
    {
        name: "Founders Factory",
        logo: "https://i.imgur.com/QtLNN2n.png",
        path: Path.FoundersFactory,
        status: "Open",
        url: "https://foundersfactorystartups.typeform.com/to/WlDiIsRh",
    },
    {
        name: "Product Hunt",
        logo: "https://i.imgur.com/ltjYNIl.png",
        path: Path.ProductHunt,
        status: "Open",
        url: "https://www.producthunt.com/posts/new",
    },
    {
        name: "Not listed here? Create custom form",
        logo: "https://i.imgur.com/CYulzzJ.png",
        path: Path.Custom,
        status: "",
        url: "",
    },
]

function AcceleratorList() {
    const [search, setSearch] = useDebouncedState("", 250)
    const [filteredSources, setFilteredSources] = useState(ACCELERATORS)

    useEffect(() => {
        const filteredSources = ACCELERATORS.filter((source) => source.name.toLowerCase().includes(search.toLowerCase()))

        setFilteredSources(filteredSources)
    }, [search])

    return (
        <Grid>
            <Grid.Col xs={12} mt={10}>
                <Group position="apart">
                    <Text>
                        Where do you want to apply?
                    </Text>

                    <TextInput
                        placeholder="Search..."
                        icon={<IconSearch size={20} />}
                        radius={50}
                        sx={{ width: "30%" }}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </Group>
            </Grid.Col>

            {filteredSources.map((source, index) => (
                <Grid.Col xs={6} key={index}>
                    <Card
                        withBorder sx={{ cursor: "pointer" }}
                        onClick={() => window.location.pathname = source.path}
                    >
                        <Group position="apart" noWrap>
                            <Group position="left" noWrap>
                                <Image
                                    src={source.logo}
                                    width={50}
                                    height="auto"
                                    radius={5}
                                />

                                <Stack spacing={0}>
                                <Text>
                                    {source.name}
                                </Text>

                                <Text color="dimmed">
                                    {source.status}
                                </Text>
                                </Stack>
                            </Group>

                            <IconArrowRight style={{ opacity: 0.15 }} />
                        </Group>
                    </Card>
                </Grid.Col>
            ))}
        </Grid>
    )
}

export default AcceleratorList
