import { Grid } from "@mantine/core"
import ApplicationForm, { Question } from "pages/application/ApplicationForm"
import ApplicationHeader from "pages/application/ApplicationHeader"
import { ACCELERATORS } from "pages/home/AcceleratorList"

function ProductHunt() {
    const { name, logo, status, url } = ACCELERATORS.find(accelerator => accelerator.name === "Product Hunt")!

    const questions: Question[] = [
        {
            question: "Enter your startup tag-line (max 60 characters)",
            maxLength: 60,
            isFree: true,
        },
        {
            question: "Describe your product (max 260 characters)",
            maxLength: 260,
        },
        {
            question: "Write a first comment",
            description: "This comment will be posted when your product launches. Adding a first comment is essential to get the discussion started."
        },
    ]

    return (
        <Grid>
            <Grid.Col xs={12}>
                <ApplicationHeader
                    name={name}
                    logo={logo}
                    status={status}
                    url={url}
                />
            </Grid.Col>

            <Grid.Col xs={12}>
                <ApplicationForm
                    fundName={name}
                    questions={questions}
                />
            </Grid.Col>
        </Grid>
    )
}

export default ProductHunt
